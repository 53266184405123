// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.wrapper[data-v-4e450fa0] {
  height: 100vh;
  width: 100vw;
  background: black;
}
.buildView[data-v-4e450fa0] {
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  position: absolute;
  transform-origin: top left;
  pointer-events:none;
  
  transform: scale(0.5) translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.frame-background[data-v-4e450fa0] {
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: 100%;
  background-position: center;
}
@keyframes slideLeft-4e450fa0 {
from {
    transform: translateX(100%);
}
to {
    transform: translateX(0);
}
}
@keyframes slideOutLeft-4e450fa0 {
from {
    transform: translateX(0);
}
to {
    transform: translateX(-100%);
}
}
.slide-left-in[data-v-4e450fa0] {
  position: absolute;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: slideRight-4e450fa0;
}
.slide-left-out[data-v-4e450fa0] {
  position: absolute;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: slideOutRight-4e450fa0;
}
@keyframes slideRight-4e450fa0 {
from {
    transform: translateX(-100%);
}
to {
    transform: translateX(0);
}
}
@keyframes slideOutRight-4e450fa0 {
from {
    transform: translateX(0);
}
to {
    transform: translateX(100%);
}
}
.slide-right-in[data-v-4e450fa0] {
  position: absolute;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: slideLeft-4e450fa0;
}
.slide-right-out[data-v-4e450fa0] {
  position: absolute;
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: slideOutLeft-4e450fa0;
}
@keyframes fade-4e450fa0 {
from {
    opacity: 0;
}
to {
    opacity: 1;
}
}
@keyframes fadeOut-4e450fa0 {
from {
    opacity: 1;
}
to {
    opacity: 0;
}
}
.fade-in[data-v-4e450fa0] {
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: fade-4e450fa0;
}
.fade-out[data-v-4e450fa0] {
  animation-timing-function: ease-in-out;
  animation-fill-mode: both;
  animation-name: fadeOut-4e450fa0;
}
.dissolve-in[data-v-4e450fa0] {
  position: absolute;
  animation-fill-mode: both;
  animation-name: fade-4e450fa0;
}
.dissolve-out[data-v-4e450fa0] {
  position: absolute;
  animation-fill-mode: both;
  animation-name: fadeOut-4e450fa0;
}


`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
